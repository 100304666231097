import React from "react";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const UserList = React.lazy(() => import("./pages/Users/UserList"));
const UserEdit = React.lazy(() => import("./pages/Users/UserEdit"));
const EmployeeList = React.lazy(() => import("./pages/Employees/EmployeeList"));
const EmployeeEdit = React.lazy(() => import("./pages/Employees/EmployeeEdit"));
const CustomerList = React.lazy(() => import("./pages/Customers/CustomerList"));
const CustomerEdit = React.lazy(() => import("./pages/Customers/CustomerEdit"));
const ProjectList = React.lazy(() => import("./pages/Projects/ProjectList"));
const ProjectEdit = React.lazy(() => import("./pages/Projects/ProjectEdit"));
const TimeBookingList = React.lazy(() =>
  import("./pages/TimeBookings/TimeBookingList")
);
const TimeBookingEdit = React.lazy(() =>
  import("./pages/TimeBookings/TimeBookingEdit")
);

const ReportCreate = React.lazy(() => import("./pages/Reports/ReportCreate"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/users", name: "Users", element: UserList },
  { path: "/users/new", name: "Add User", element: UserEdit },
  { path: "/users/:id", name: "Edit User", element: UserEdit },
  { path: "/employees", name: "Employees", element: EmployeeList },
  { path: "/employees/new", name: "Add Employee", element: EmployeeEdit },
  { path: "/employees/:id", name: "Edit Employee", element: EmployeeEdit },
  { path: "/customers", name: "Customers", element: CustomerList },
  { path: "/customers/new", name: "Add Customer", element: CustomerEdit },
  { path: "/customers/:id", name: "Edit Customer", element: CustomerEdit },
  { path: "/projects", name: "Projects", element: ProjectList },
  { path: "/projects/new", name: "Add Project", element: ProjectEdit },
  { path: "/projects/:id", name: "Edit Project", element: ProjectEdit },
  { path: "/time-bookings", name: "Time Bookings", element: TimeBookingList },
  {
    path: "/time-bookings/new",
    name: "Add Time Booking",
    element: TimeBookingEdit,
  },
  {
    path: "/time-bookings/:id",
    name: "Edit Time Booking",
    element: TimeBookingEdit,
  },
  {
    path: "/reports/create",
    name: "Create Report",
    element: ReportCreate,
  },
];

export default routes;
