import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import client from '../feathers'

const initialState = {
  user: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  reauthenticated: false,
  errorMessage: '',
  errorCode: null
}

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async ({ email, password, from = null }, thunkAPI) => {
    try {
      const result = await client.authenticate({
        strategy: 'local',
        email,
        password
      })

      return result.user
    } catch (e) {
      // console.log(e)
      return thunkAPI.rejectWithValue({
        code: e.code,
        message: e.message
      })
    }
  }
)

export const reauthenticateUser = createAsyncThunk('users/reauthenticateUser', async (thunkAPI) => {
  try {
    const result = await client.reAuthenticate()
    return result.user
  } catch (e) {
    // console.log(e);
    return thunkAPI.rejectWithValue(e)
  }
})

export const logoutUser = createAsyncThunk('users/logoutUser', async (thunkAPI) => {
  try {
    await client.logout()
    return true
  } catch (e) {
    console.log(e)
    return thunkAPI.rejectWithValue(e)
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        // state.email = payload.email;
        // state._id = payload._id;
        state.user = payload
        state.isFetching = false
        state.isSuccess = true
        // state.isError = false;
        // state.errorMessage = "";
        // state.errorCode = null;
        return state
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        console.log('payload', payload)
        state.isFetching = false
        state.isError = true
        state.errorMessage = payload.message
        state.errorCode = payload.code
      })
      .addCase(loginUser.pending, (state, { payload }) => {
        state.isFetching = true
        state.isError = false
        state.errorMessage = ''
        state.errorCode = null
      })
      .addCase(reauthenticateUser.fulfilled, (state, { payload }) => {
        state.user = payload
        state.isFetching = false
        state.isSuccess = true
        // state.isError = false;
        // state.errorMessage = "";
        // state.errorCode = null;
        state.reauthenticated = true
        return state
      })
      .addCase(reauthenticateUser.rejected, (state, { payload }) => {
        // console.log('payload', payload);
        state.isFetching = false
        state.isError = false
        state.reauthenticated = true
        //state.errorMessage = payload.message;
      })
      .addCase(reauthenticateUser.pending, (state, { payload }) => {
        state.isFetching = true
      })
      .addCase(logoutUser.fulfilled, (state, { payload }) => {
        state.user = null
        state.isFetching = false
        state.isSuccess = false
        state.isError = false
        return state
      })
      .addCase(logoutUser.rejected, (state, { payload }) => {
        console.log('payload', payload)
      })
      .addCase(logoutUser.pending, (state, { payload }) => {})
  }
})

// Action creators are generated for each case reducer function
export const {} = userSlice.actions

export const userSelector = (state) => state.user

export default userSlice.reducer
