export const logo = [
  '348 83',
  `
  <g>
	<path style="fill:#FFFFFF;" d="M169.46,0.56H178l4.7,15.9c0.92,3.12,1.84,6.44,2.66,9.56h0.1c0.87-3.17,1.84-6.49,2.81-9.61
		l4.91-15.85h5.93l4.86,15.85c0.97,3.17,1.94,6.44,2.81,9.61h0.1c0.82-3.12,1.79-6.54,2.76-9.72l4.75-15.75h8.18l-11.4,37.02h-8.59
		l-3.99-13.04c-0.97-3.12-1.79-5.88-2.61-8.85h-0.1c-0.82,2.97-1.69,5.88-2.66,9l-3.99,12.89h-8.49L169.46,0.56z"/>
	<path style="fill:#FFFFFF;" d="M223.56,19.07C223.56,8.28,231.9,0,242.89,0s19.33,8.28,19.33,19.07c0,10.79-8.33,19.07-19.33,19.07
		S223.56,29.86,223.56,19.07z M254.09,19.07c0-6.54-4.81-11.71-11.2-11.71s-11.2,5.16-11.2,11.71s4.81,11.71,11.2,11.71
		S254.09,25.62,254.09,19.07z"/>
	<path style="fill:#FFFFFF;" d="M267.85,0.56h11.61c4.4,0,8.54,0.61,11.5,2.45c2.71,1.69,4.45,4.45,4.45,8.69
		c0,4.76-2.61,8.64-7.52,10.23l10.43,15.65h-9.36l-9.36-14.62h-3.63v14.62h-8.13V0.56z M280.07,16.87c2.2,0,3.89-0.26,5.11-0.87
		c1.38-0.72,2.1-1.94,2.1-3.73c0-1.74-0.61-2.86-2.1-3.63c-1.28-0.66-3.27-0.97-6.08-0.97h-3.12v9.2H280.07z"/>
	<path style="fill:#FFFFFF;" d="M301.75,0.56h8.13v16.36l0.1,0.05l12.94-16.41h9.66l-14.01,17.18l15.19,19.84h-10.02l-13.75-18.51
		l-0.1,0.05v18.46h-8.13V0.56z"/>
</g>
<g>
	<path style="fill:#FFFFFF;" d="M130.44,45.47h8.13v37.02h-8.13V45.47z"/>
	<path style="fill:#FFFFFF;" d="M145.37,72.11c3.94,2.66,8.9,3.83,11.81,3.83c2.97,0,5.16-1.18,5.16-3.94
		c0-3.43-3.48-3.73-8.23-5.27c-5.11-1.69-9.72-4.19-9.72-10.64c0-6.9,5.22-11.2,12.99-11.2c3.63,0,8.08,0.92,11.3,2.66v8.03
		c-3.94-2.45-8.49-3.58-11.35-3.58c-2.76,0-4.76,1.02-4.76,3.48c0,3.27,3.43,3.53,7.87,5.01c5.57,1.84,10.07,4.45,10.07,10.99
		c0,7.21-5.47,11.56-13.45,11.56c-3.68,0-8.23-0.92-11.71-2.71V72.11z"/>
	<path style="fill:#FFFFFF;" d="M188.42,45.47h10.38c6.6,0,11.71,1.07,15.54,3.73c4.55,3.17,7.11,8.39,7.11,14.73
		c0,5.83-2.2,10.79-6.14,14.01c-3.78,3.07-9.1,4.55-16.87,4.55h-10.02V45.47z M198.8,75.38c5.27,0,8.64-0.92,10.94-2.91
		c2.2-1.89,3.58-4.86,3.58-8.54c0-3.89-1.53-7.16-4.29-9.1c-2.61-1.84-6.14-2.25-10.28-2.25h-2.2v22.8H198.8z"/>
	<path style="fill:#FFFFFF;" d="M225.29,63.98c0-10.79,8.33-19.07,19.33-19.07s19.33,8.28,19.33,19.07
		c0,10.79-8.33,19.07-19.33,19.07S225.29,74.77,225.29,63.98z M255.82,63.98c0-6.54-4.81-11.71-11.2-11.71s-11.2,5.16-11.2,11.71
		s4.81,11.71,11.2,11.71S255.82,70.53,255.82,63.98z"/>
	<path style="fill:#FFFFFF;" d="M284.36,67.56c-2.4-3.22-4.65-6.34-6.95-9.56l-0.1,0.05c0.05,3.53,0.1,7.11,0.1,10.69v13.75h-7.82
		V45.47h7.67l11.1,14.93c2.4,3.22,4.65,6.34,6.95,9.56l0.1-0.05c-0.05-3.53-0.1-7.11-0.1-10.69V45.47h7.82v37.02h-7.67L284.36,67.56
		z"/>
	<path style="fill:#FFFFFF;" d="M310.38,45.47h22.86v7.11h-14.73v7.26h12.68v6.75h-12.68v8.79h15.29v7.11h-23.42V45.47z"/>
	<path style="fill:#FFFFFF;" d="M344.03,73.85c2.56,0,4.7,2.05,4.7,4.6s-2.15,4.6-4.7,4.6s-4.7-2.04-4.7-4.6
		S341.47,73.85,344.03,73.85z"/>
</g>
<polygon style="fill:#FFFFFF;" points="130.63,1.13 82.75,55.78 69.48,40.63 104.03,1.19 103.97,1.13 81.81,1.13 33.93,55.78 
	22.27,42.48 0,42.48 33.93,81.21 58.34,53.34 82.75,81.21 152.85,1.19 152.79,1.13 "/>
    `
]
