import io from 'socket.io-client'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client'

const { REACT_APP_API } = process.env

const socket = io(REACT_APP_API)
const client = feathers()

client.configure(socketio(socket))

client.configure(
  authentication({
    storageKey: 'auth'
  })
)

export default client
