import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        work is done.
        <span className="ms-1">&copy; 2023</span>
      </div>

      <div className="ms-auto small">
        <span className="me-1">developed by</span>
        <span className="me-1">
          <a
            href="https://eichhorn.services"
            className="link-secondary text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
          >
            eichhorn media + it services
          </a>
        </span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
